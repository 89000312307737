
import {
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import React from 'react'

export const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        background: 'white'
      }
    }
  }
})

export const headerRightAlign = (columnMeta) => <div className="text-right">{columnMeta.label}</div>

export default (props) => <MuiThemeProvider theme={theme}>
    <MUIDataTable {...props} />
</MuiThemeProvider>
