/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useGetListingParticipants, useGetListingQuery, useSaveSelectAlternativeBidsLotMutation } from '../../../components/api'
import { CreateSelect } from '../../../components/Form'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'

/**
 * @typedef {Object} Buyer
 * @property {number} company_id e.g. 4,
 * @property {string} name e.g. "Mobile Zone",
 * @property {string} country e.g. "hk",
 * @property {string} status e.g. "active"
 * @property {string} price e.g. "2000.12"
 */

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const {
    openModal = true,
    setOpenModal = NOOP,
    selectedLots = []
  } = props
  const { listingId = '325' } = props
  const selectedLotIds = selectedLots.map(lot => lot.id)
  const participantsListQuery = useGetListingParticipants(listingId)
  const { data: participants = [] } = participantsListQuery
  /** @type {Object.<string, Buyer} */
  const participantsById = participants.reduce((prev, curr) => {
    prev[curr.company_id] = curr
    return prev
  }, {})

  const [newBuyers, setNewBuyers] = React.useState({})

  const setBuyer = (listingLotId, buyerId, highestBuyerId, highestBid) => {
    console.log('setBuyer', { listingLotId, buyerId, highestBuyerId, highestBid })
    setNewBuyers(ps => ({ ...ps, [`${listingLotId}`]: { listingId, listingLotId, buyerId, highestBuyerId, highestBid } }))
  }

  // query
  const applyAlternativeBidsMutation = useSaveSelectAlternativeBidsLotMutation()
  const listingLotQuery = useGetListingQuery({ listingId, isVendor: true })
  const { data: { listing_lots = [] } = {} } = listingLotQuery

  // only show selected lots
  const selectedLotsData = listing_lots.filter(lot => selectedLotIds.indexOf(lot.id) !== -1)

  // functions
  const onHide = () => {
    setOpenModal(false)
    setNewBuyers({})
    applyAlternativeBidsMutation.reset()
  }

  const internalOnSave = () => {
    applyAlternativeBidsMutation.mutate(Object.values(newBuyers))
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Select alternative bid</h5>

                <p>Use the dropdown to select an alternative bid.</p>

                { listingLotQuery.isLoading
                  ? <div className="d-flex justify-content-center m-4"><Spinner /></div>
                  : <>
                    <MUIDataTable
                    data={selectedLotsData}
                    columns={[
                      { label: 'Lot', name: 'lot_number' },
                      {
                        label: 'Alternative Bid',
                        name: 'id',
                        options: {
                          customBodyRender: function(listingLotId, meta) {
                            let { highest_company_id: highestBuyerId, buyer_bids: buyers } = selectedLotsData[meta.rowIndex]
                            if (buyers.length <= 1) {
                              // only zero or one buyer! Can't select alternative buyer!
                              return <i className="text-muted">{buyers.length === 1 ? 'Only one bidder for this item.' : 'No bidders for this item.'}</i>
                            }
                            // TODO Commented out current buyer for now... https://trello.com/c/L2hZPrgO/73-select-alternative-bid-hide-selected-buyer-from-options-menu
                            // let { highest_company_id: highestBuyerId, buyer_bids: buyers, selected_buyer_company_id: selectedCompanyId } = selectedLotsData[meta.rowIndex]
                            // // exclude the currently selected buyer from the list...
                            // let excludeBuyerId = selectedCompanyId === null ? highestBuyerId : selectedCompanyId
                            // excludeBuyerId = excludeBuyerId.startsWith('tmp-') ? excludeBuyerId.substring(4) : `${excludeBuyerId}`
                            // buyers = buyers.filter(b => (b.company_id).toString() !== (excludeBuyerId).toString())
                            buyers = buyers.map(buyer => {
                              /** @type {Buyer} */
                              const tmp = Object.assign(buyer, participantsById[buyer.company_id])
                              tmp.priceAsFloat = parseFloat(tmp.price)
                              tmp.label = `$${tmp.priceAsFloat.toLocaleString()} - ${tmp.name}`
                              tmp.value = (tmp.company_id).toString()
                              return tmp
                            }).sort((a, b) => b.priceAsFloat - a.priceAsFloat)
                            // return JSON.stringify({ highestBuyerId, selectedCompanyId })
                            return <CreateSelect
                                className="mb-0"
                                title="Buyer"
                                naked={true}
                                // value={data['listing[currency_value]']}
                                onChange={e => {
                                  /** @type {Buyer} */
                                  const buyer = buyers.find(b => b.value === e.target.value)
                                  if (buyer) {
                                    setBuyer(listingLotId, buyer.value, highestBuyerId, buyer.price)
                                  }
                                }}
                                placeholder="Select a bid"
                                disabled={false}
                                options={buyers}
                                />
                          }
                        }
                      }
                    ]}
                    options={{
                      selectableRows: 'none',
                      pagination: false,
                      jumpToPage: false,
                      rowsPerPage: 15,
                      print: false,
                      search: false,
                      download: false,
                      sort: false,
                      filter: false,
                      viewColumns: false,
                      elevation: 0,
                      setTableProps: () => ({ size: 'small' })
                    }}
                    />

          </>}

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary">
                        {applyAlternativeBidsMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { applyAlternativeBidsMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { applyAlternativeBidsMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating benchmarks. ' + applyAlternativeBidsMutation.error.message}</Alert> }

                { process.env.NODE_ENV !== 'production' && <>
                    <pre>newBuyers = {JSON.stringify(newBuyers, null, 2)}</pre>
                    <pre>participantsListQuery = {JSON.stringify(participantsListQuery, null, 2)}</pre>
                    <pre>selectedLots = {JSON.stringify(selectedLots, null, 2)}</pre>
                    <pre>listingLotQuery = {JSON.stringify(listingLotQuery, null, 2)}</pre>
                </>}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
